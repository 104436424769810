import type { StructuredTableCellFragment } from '#graphql-operations'

export type StructuredTableProps = {
  thead: StructuredTableCellFragment[][]
  tbody: StructuredTableCellFragment[][]
  tfoot: StructuredTableCellFragment[][]
}

type CellValue = string | number | undefined | StructuredTableCellFragment

function mapCellValue(
  v: CellValue,
  tag: 'td' | 'th' = 'td',
): StructuredTableCellFragment {
  if (typeof v === 'object') {
    return v
  }

  return {
    tag,
    content: (v || '').toString(),
  }
}

/**
 * Define a structured table to be used with the <StructuredTable> component.
 */
export default function (
  heading: CellValue[],
  body: CellValue[][],
  footer?: CellValue[][],
): StructuredTableProps {
  return {
    thead: [heading.map((v) => mapCellValue(v, 'th'))],
    tbody: body.map((cells) => cells.map((v) => mapCellValue(v))),
    tfoot:
      footer?.map((cells) => cells.map((v) => mapCellValue(v, 'th'))) || [],
  }
}
