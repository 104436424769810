<template>
  <figure class="overflow-auto">
    <table
      class="table"
      :class="{ 'has-mobile-style': hasMobileStyle, 'is-wide': isWide }"
    >
      <thead v-if="thead.length">
        <tr v-for="(row, i) in thead" :key="'thead_' + i">
          <component
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'thead_' + i + '_' + j"
            v-bind="cell.attributes"
          >
            <div v-html="cell.content" />
          </component>
        </tr>
      </thead>

      <tbody v-if="tbody.length">
        <tr v-for="(row, i) in tbody" :key="'tbody_' + i">
          <component
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'tbody_' + i + '_' + j"
            v-bind="getCellAttributes(cell, j)"
          >
            <div v-html="cell.content" />
          </component>
        </tr>
      </tbody>

      <tfoot v-if="tfoot.length">
        <tr v-for="(row, i) in tbody" :key="'tfoot_' + i">
          <component
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'tfoot_' + i + '_' + j"
            v-bind="cell.attributes"
          >
            <div v-html="cell.content" />
          </component>
        </tr>
      </tfoot>
    </table>
    <figcaption v-if="caption">
      {{ caption }}
    </figcaption>
  </figure>
</template>

<!--

To add a caption underneath the table, use this component within a figure:

<figure>
  <StructuredTable ... />
  <figcaption>
    Caption goes here
  </figcaption>
</figure>
-->

<script lang="ts" setup>
import type {
  StructuredTableCellFragment,
  StructuredTableCellHeadFragment,
} from '#graphql-operations'

const props = withDefaults(
  defineProps<{
    thead: Array<
      StructuredTableCellFragment | StructuredTableCellHeadFragment
    >[]
    tbody: StructuredTableCellFragment[][]
    tfoot?: StructuredTableCellFragment[][]
    caption?: string
    mobileStyle?: 'horizontal' | 'vertical'
  }>(),
  {
    tfoot: () => [],
    caption: '',
    mobileStyle: 'vertical',
  },
)

const hasMobileStyle = computed(() => props.mobileStyle === 'vertical')

// Guesstimate whether the table will be wide, in which case each cell has
// a min-width applied, to prevent having cells that are just 40px wide and
// where text breaks after every 6 letters.
const isWide = computed(() => (props.thead[0]?.length || 0) > 3)

function getCellAttributes(cell: StructuredTableCellFragment, index: number) {
  // Clone the attributes before altering them.
  const attributes: Record<string, string> = JSON.parse(
    JSON.stringify(cell.attributes || {}),
  )

  if (
    props.thead.length &&
    props.thead[0] !== undefined &&
    props.thead[0][index] !== undefined
  ) {
    const cell = props.thead[0][index]
    const headLabel =
      'plainText' in cell && cell.plainText ? cell.plainText : cell.content
    attributes['data-head-label'] = headLabel.trim()
  }
  return attributes
}
</script>
